/* eslint-disable no-alert */
/* eslint-disable import/prefer-default-export */
import mediumZoom from 'medium-zoom';
import React from 'react';
// eslint-disable-next-line import/named
import { ThemeProvider } from './src/providers/ThemeContext';
import './src/tailwind/global.css';

// Needs to be named export.
export const wrapPageElement = ({ element }) => {
  if (element.key === '/archive') {
    return <ThemeProvider>{element}</ThemeProvider>;
  }
  return <ThemeProvider>{element}</ThemeProvider>;
};

export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    'This site has been updated. Reload to display the latest version?',
  );

  if (answer === true) {
    window.location.reload();
  }
};

// Took from https://github.com/premieroctet/gatsby-remark-images-zoom.

const mediumZoomOptions = {
  margin: 20,
  zIndex: 42,
};

export const onClientEntry = () => {
  const { zIndex } = mediumZoomOptions;

  // Inject styles.
  const styles = `
    .medium-zoom-overlay, .medium-zoom-image {
      z-index: ${zIndex};
    }
  `;

  const node = document.createElement('style');
  node.id = 'medium-zoom-styles';
  node.innerHTML = styles;
  document.head.appendChild(node);
};

export const onRouteUpdate = () => {
  mediumZoom('.gatsby-resp-image-image', mediumZoomOptions);
};
