import React, { useEffect, useState } from 'react';

const DARK_MODE = 'darkMode';
function getLocalDarkState() {
  if (typeof window !== 'undefined') {
    return localStorage.getItem(DARK_MODE) === 'null'
      ? false
      : localStorage.getItem(DARK_MODE) === 'true';
  }
  return false;
}

/* Potentially Enable system level dark mode detection.
 * macOS Mojave + Safari Technology Preview Release 68 to test this currently.
 * const supportsDarkMode = () => window.matchMedia('(prefers-color-scheme: dark)').matches === true;
 */

const defaultState = {
  dark: getLocalDarkState(),
  toggleDark: () => {},
};

const ThemeContext = React.createContext(defaultState);

const ThemeProvider = ({ children }) => {
  const [dark, setDark] = useState(getLocalDarkState());

  const toggleDark = () => setDark(!dark);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      localStorage.setItem(DARK_MODE, dark);
    }

    if (dark === true) {
      document.querySelector('body').classList.add('dark');
      document.querySelector('body').classList.remove('light');
    } else {
      document.querySelector('body').classList.add('light');
      document.querySelector('body').classList.remove('dark');
    }
  }, [dark]);

  return (
    <ThemeContext.Provider
      value={{
        dark,
        toggleDark,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeContext;

export { ThemeProvider };
