import React from 'react';

export function AlertWork() {
  return (
    <Alert title="Work in Progress">This page and it's content are still being worked on.</Alert>
  );
}

export default function Alert({ title, children }) {
  return (
    <div className="rounded bg-primary-100 p-4">
      <div className="flex-col">
        <div className="flex">
          <svg className="h-10 w-10 text-primary-400 mr-2" fill="currentColor" viewBox="0 0 20 20">
            <path
              fillRule="evenodd"
              d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
              clipRule="evenodd"
            />
          </svg>
          <h3 className="font-display font-medium text-primary-600 m-0">{title}</h3>
        </div>
        <div className="mt-2 text-sm font-sans">
          <p className="text-gray-800">{children}</p>
        </div>
      </div>
    </div>
  );
}
